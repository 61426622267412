@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz@8..144&family=Roboto:wght@300;500&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Serif", serif;
  font-weight: 400;
}

body {
  font-family: "Roboto Serif", serif;
  color: "inherit !important";
  border-radius: 0;
}

:root {
  --bs-body-font-weight: 400;
}

.ant-select-selection-item {
  font-weight: 400;
}

p {
  font-family: "Roboto Serif";
  font-weight: 400;
}

p a {
  text-decoration: underline;
  color: #2c6496;
}

.link-inverted {
  text-decoration: underline;
  color: #ffffff;
}

/* icons */

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 300,
    "GRAD" 0,
    "opsz" 48;
}

a {
  /* no underline */
  text-decoration: none;
  color: inherit;
  outline: none;
}

.spaced-sans-serif {
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 500;
  letter-spacing: 2.4px;
}

a:hover {
  color: inherit;
}

input:focus {
  outline: none;
}

a.highlighted-link {
  box-shadow: inset 0 0 0 0 #227773;
  color: #2c6496;
  padding-top: 5px;
  padding-bottom: 5px;
  transition:
    color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out,
    padding-left 0.2s ease-in-out,
    padding-right 0.2s ease-in-out;
}

a.highlighted-link:hover {
  box-shadow: inset 800px 0 0 0 #2c6496;
  color: white;
}

:target {
  scroll-margin-top: 300px;
}

.ant-btn-link {
  color: rgba(0, 0, 0, 0.85);
}

.ant-btn-link:hover {
  color: inherit;
}

.ant-checkbox-input {
  border-radius: 0 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: white;
  font-family: "Roboto Serif";
}

.ant-input {
  background-color: white;
  font-family: "Roboto Serif";
}

.ant-switch-handle::before {
  border-radius: 0 !important;
}

button.ant-switch {
  border-radius: 0 !important;
}

b {
  font-weight: 600;
}

h1 {
  margin-bottom: 25px;
}

h3 {
  margin-bottom: 15px;
}

.explain-ai-button {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 5px 10px;
  font-family: "Roboto Serif", serif;
  color: white;
  background-color: #2c6496;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s ease-in-out;
}

.info-icon-wrapper:hover .explain-ai-button {
  display: block; /* Show the button on hover */
}
